import { Injectable } from '@angular/core';
import { BaseDetailResolver } from './base-detail.resolver';
import { Video } from 'src/app/models/video';
import { VideoApiClientService } from '../api/video-api-client/video-api-client.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotFoundService } from '../not-found/not-found.service';
import { VideoTopApiClientService } from '../api/video-top-api-client/video-top-api-client.service';
import { VideoCarouselApiClientService } from '../api/video-carousel-api-client/video-carousel-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class VideoResolver extends BaseDetailResolver<Video> {
  protected idParam = 'videoId';

  constructor(
    protected videoApiClientService: VideoApiClientService,
    protected videoTopApiClientService: VideoTopApiClientService,
    protected videoCarouselApiClientService: VideoCarouselApiClientService,
    protected notFoundService: NotFoundService
  ) {
    super();
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const from = route.queryParams['from'];
    let request;

    switch (from) {
      case 'top':
        request = this.videoTopApiClientService.get(route.paramMap.get(this.idParam));
        break;
      case 'carousel':
        const carouselId = route.queryParams['carouselId'];
        const service = this.videoCarouselApiClientService;
        service.endpoint = `carousel/${carouselId}/video`;
        request = service.get(route.paramMap.get(this.idParam));
        break;
      case 'keep-watching':
        request = this.videoApiClientService.actionGet(
          { id: route.paramMap.get(this.idParam) },
          'keep-watching'
        );
        break;
      case 'see-recommended':
        request = this.videoApiClientService.actionGet(
          { id: route.paramMap.get(this.idParam) },
          'see-recommended'
        );
        break;
      default:
        request = this.videoApiClientService.get(route.paramMap.get(this.idParam));
        break;
    }
    return request.pipe(
      map((data) => {
        this.notFoundService.showNotFound = false;
        return data;
      }),
      catchError((error) => {
        if (error.status === 404) {
          this.notFoundService.showNotFound = true;
        }
        return of(undefined);
      })
    );
  }
}

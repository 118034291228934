// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

ion-button {
  margin: 0;
  height: 48px;
  width: 100%;
  --border-radius: 5px;
  --box-shadow: none;
  text-transform: none;
  --background-activated-opacity: .9;
  --background-focused-opacity: .9;
  --background-activated: var(--background);
  --background-focused: var(--background);
  --ripple-color: transparent;
}
ion-button.small {
  height: 38px;
}
ion-button.transparent {
  --background: var(--ion-color-transparent);
}
ion-button.primary {
  --background: var(--ion-color-primary);
}
ion-button.secondary {
  --background: var(--ion-color-secondary);
}
ion-button.dark {
  --background: var(--ion-color-white);
  --border-color: var(--ion-color-black);
  --border-style: solid;
  --border-width: 2px;
}
ion-button.white {
  --background: var(--ion-color-white);
}
ion-button.light {
  --background: var(--ion-color-light);
}
ion-button.disabled {
  --background: var(--ion-color-disabled);
}
ion-button.shape-circle {
  --border-radius: 100%;
}
ion-button .button-disabled {
  --background: var(--ion-color-disabled) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/button/button/button.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,SAAA;EACA,YAAA;EACA,WAAA;EACA,oBAAA;EACA,kBAAA;EACA,oBAAA;EACA,kCAAA;EACA,gCAAA;EACA,yCAAA;EACA,uCAAA;EACA,2BAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,0CAAA;AAAJ;AAGE;EACE,sCAAA;AADJ;AAIE;EACE,wCAAA;AAFJ;AAKE;EACE,oCAAA;EACA,sCAAA;EACA,qBAAA;EACA,mBAAA;AAHJ;AAME;EACE,oCAAA;AAJJ;AAQE;EACE,oCAAA;AANJ;AASE;EACE,uCAAA;AAPJ;AAWE;EACE,qBAAA;AATJ;AAYE;EACE,kDAAA;AAVJ","sourcesContent":[":host {\n  display: block;\n}\n\nion-button {\n  margin: 0;\n  height: 48px;\n  width: 100%;\n  --border-radius: 5px;\n  --box-shadow: none;\n  text-transform: none;\n  --background-activated-opacity: .9;\n  --background-focused-opacity: .9;\n  --background-activated: var(--background);\n  --background-focused: var(--background);\n  --ripple-color: transparent;\n\n  &.small {\n    height: 38px;\n  }\n\n  &.transparent {\n    --background: var(--ion-color-transparent);\n  }\n\n  &.primary {\n    --background: var(--ion-color-primary);\n  }\n\n  &.secondary {\n    --background: var(--ion-color-secondary);\n  }\n\n  &.dark {\n    --background: var(--ion-color-white);\n    --border-color: var(--ion-color-black);\n    --border-style: solid;\n    --border-width: 2px;\n  }\n\n  &.white {\n    --background: var(--ion-color-white);\n  }\n\n\n  &.light {\n    --background: var(--ion-color-light);\n  }\n\n  &.disabled {\n    --background: var(--ion-color-disabled);\n  }\n\n  // Shape\n  &.shape-circle {\n    --border-radius: 100%;\n  }\n\n  .button-disabled {\n    --background: var(--ion-color-disabled) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, Input, OnInit } from '@angular/core';
import { Option } from 'src/app/models/option';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'merz-input-select-select',
  templateUrl: './input-select-select.component.html',
  styleUrls: ['./input-select-select.component.scss'],
})
export class InputSelectSelectComponent extends InputComponent {
  // @Input('filter-service') filterService: any;
  @Input() field: string;
  @Input() placeholder: string;
	@Input() options: Option[] = [];
	@Input('option-selected-value') optionSelectedValue: string;

  constructor() { super(); }

  ngOnInit() {
    this.updateSelected();
  }

  updateSelected(){
    if(this.optionSelectedValue){
      this.options.find((option) => option.value == this.optionSelectedValue).checked = true;
    }
  }

  get optionSelected(){
    return this.options.find((option) => option.checked);
  }

  compareWith(o1: Option, o2: Option) {
    return o1 && o2 ? o1.value === o2.value : o1 === o2;
  }

  selectOption(e){
    const option: Option = e.target.value;
    this.formControl.setValue(option.value);
  }

}

import { Component, Input } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { Option } from 'src/app/models/option';

@Component({
  selector: 'merz-input-radio-nps',
  templateUrl: './input-radio-nps.component.html',
  styleUrls: ['./input-radio-nps.component.scss'],
})
export class InputRadioNpsComponent extends InputComponent {
  @Input() options: Option[] = [];

  valueSelected = '';

  ngOnInit(): void {
    this.getSelectedValue();
  }

  getSelectedValue() {
    const selectedOption = this.options.find(option => option.checked);
    if (selectedOption) {
      this.valueSelected = selectedOption.value;
    }
  }

  selectOption(selectedValue: any) {
    this.valueSelected = selectedValue.value;
    this.formControl.setValue(selectedValue.value);
  }
}
